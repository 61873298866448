import './App.css';
import PublicRouter from './Routes/PublicRouter';

const App: React.FunctionComponent<{}> = (props) =>{
  return (
    <div className='app'>
     <PublicRouter/>
    </div>
  );
}
export default App;