import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getUserId } from '../../Services/Authentication.service'
import { getUserProfileImage } from '../../Services/Profile'
import {  get_project_details} from '../../Services/WorkflowServices'
import HeaderDash from '../Header/HeaderDash'
import HeaderFilter from '../Header/HeaderFilter'
import Sidebar from '../Sidebar/Sidebar'
import ChartsWrapper from './ChartsWrapper'
import DataWrapper from './DataWrapper'

const Analytics:React.FunctionComponent<{}> = (props) =>{

     let {workflow_id} :any = useParams();
     const [isTypeSet, setIsTypeSet] = useState<boolean>(false);
     const [dataDetails, setDataDetails] = useState<any>(null);
    //  const [profileImage, setProfileImage] = useState<any>();

    useEffect(() => {
        get_project_details(workflow_id).then((data) => {
            setIsTypeSet(true)
            setDataDetails(data);
        }).catch(err => {
            console.log("Error");
        });
        // getUserProfileImage(getUserId()).then((response) => {
        //     setProfileImage(response.profile_image)
        // }).catch(err => {
        //    console.log("error")
        // })
    }, [])
    
    return isTypeSet ?(
    <div>
            {/* <HeaderDash profileImage={profileImage}/> 
            <Sidebar profileImage={profileImage} statusProject={dataDetails.status} workflow_id={workflow_id} /> */}
            <HeaderFilter totalData={dataDetails.data_count} />
            <div className="page-content-wrapper">
             <div className="container-fluid">
               <div style={{marginTop: "40px"}}>
                <div className='row'>
                    <div className='col-xl-5'>
                     <DataWrapper type={dataDetails.category} workflow_id={workflow_id}/>
                    </div>
                    <div className='col-xl-7'>
                    <ChartsWrapper type={dataDetails.category} workflow_id={workflow_id}/> 
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    ): <></>
}

export default Analytics