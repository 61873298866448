import React, { useEffect, useState } from 'react'
import { getUserDetails, getUserId } from '../../Services/Authentication.service';
import { getUserProfileImage, updateProfileImage } from '../../Services/Profile';
import HeaderDash from '../Header/HeaderDash';

const ProfileImage: React.FunctionComponent<{image : any}> = (props) => {

    const [image, setImage] = useState<any>(props.image);


    const ImageChange = (e) => {
        const readerValue = new FileReader();
        readerValue.readAsDataURL(e.target.files[0]);
        readerValue.onload = () => {
            const imageBase64: any = readerValue.result as string;
            updateProfileImage(imageBase64).then((response) => {
                if (response.msg = "success") {
                    setImage(imageBase64)
                } else {
                    console.log("error")
                }
            })
        }
    }

    return image ?(
        <div>
            <div className="profile-pic">
                <label className="-label" htmlFor="file">
                    <span><i className="fas fa-image"></i></span>
                    <span style={{fontSize : "11px"}}>Change Image</span>
                </label>
                <input id="file" type="file" accept="image/*" onChange={(e) => ImageChange(e)} />
                <img src={image} id="output" width="200" />
            </div>
        </div>

    ):<></>
}

export default ProfileImage
