import axios from "axios";
import { WordCloud, WordCloudData } from "../Models/WordCloud";
import { getUserId } from "./Authentication.service";

// Donut Chart "Overall Sentiment"
const url = "https://api.coralytics.com"
export const getSentimentDonut = (type: string,workflow_id : string )=> {
  const apiUrl = url+"/get_sentiment_donut";
   const user = {  "type": type ,"workflow_id": workflow_id}
  return axios.post(apiUrl, user,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "") 
    }
  }).then((response) => {
   
    return response
  });
};

//Line Chart "Sentiment over time"
export const getSentimentOverTime = (type: string,workflow_id : string) => {
  const apiUrl = url+"/get_sentiment_over_time";
   const user = {  "type": type ,"workflow_id": workflow_id}
  return axios.post(apiUrl, user,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "") 
    }
  }).then((response) => {
    
    return response
  });
};

//Brand Recommendation
export const getBrandRecommendationDonut = (type: string, workflow_id : string) => {
  const apiUrl = url+"/get_brand_recommendation_donut";
   const user = {  "type": type ,"workflow_id": workflow_id}
  return axios.post(apiUrl, user,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "") 
    }
  }).then((response) => {
   
    return response
  });
};
//Line Chart "Brand Recommendation over time"
export const getBrandOverTime = (type: string,workflow_id : string) => {
  const apiUrl = url+"/get_brand_over_time";
   const user = {  "type": type ,"workflow_id": workflow_id}
  return axios.post(apiUrl, user,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "") 
    }
  }).then((response) => {
  
    return response
  });
};


//Emotion Analysis
export const getEmotionDonut = (type: string,workflow_id : string) => {
  const apiUrl = url+"/get_emotion_donut";
   const user = { "type": type ,"workflow_id": workflow_id}
  return axios.post(apiUrl, user,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "") 
    }
  }).then((response) => {
    
    return response
  });
};


//Line Chart "Emotion over time"
export const getEmotionOverTime = (type: string,workflow_id : string) => {
  const apiUrl = url+"/get_emotion_over_time";
   const user = { "type": type ,"workflow_id": workflow_id}
  return axios.post(apiUrl, user,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "") 
    }
  }).then((response) => {
    return response
  });
};

//Personality Traits
export const getPersonalityDonut = (type: string,workflow_id : string )=> {
  const apiUrl = url+"/get_personality_donut";
   const user = {  "type": type ,"workflow_id": workflow_id}
  return axios.post(apiUrl, user,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "") 
    }
  }).then((response) => {
    return response
  });
};
//Line Chart "Sentiment over time"
export const getPersonalityOverTime = (type: string,workflow_id : string) => {
  const apiUrl = url+"/get_personality_over_time";
   const user = {  "type": type ,"workflow_id": workflow_id}
  return axios.post(apiUrl, user,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "") 
    }
  }).then((response) => {
    return response
  });
};

//Communication and Tonality
export const getTonalityDonut = (type: string,workflow_id : string) => {
  const apiUrl = url+"/get_tonality_donut";
   const user = {  "type": type ,"workflow_id": workflow_id}
  return axios.post(apiUrl, user,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "") 
    }
  }).then((response) => {
    return response
  });
};
//Line Chart "Sentiment over time"
export const getTonalityOverTime = (type: string,workflow_id : string) => {
  const apiUrl = url+"/get_tonality_over_time";
   const user = {  "type": type ,"workflow_id": workflow_id}
  return axios.post(apiUrl, user,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "") 
    }
  }).then((response) => {
    return response
  });
};


// Horizontal Bar Chart "Sentiment by Topic"
export const getSentimentByTopic = (type: string,workflow_id : string) => {
  const apiUrl = url+"/get_sentiment_by_topic";
   const user = {  "type": type ,"workflow_id": workflow_id}
  return axios.post(apiUrl, user,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "") 
    }
  }).then((response) => {
    return response
  });
};

// Horizontal Bar Chart "Topic"
export const getTopics = (type: string, workflow_id : string) => {
  const apiUrl = url+"/get_topics";
   const user = { "type": type ,"workflow_id": workflow_id}
  return axios.post(apiUrl, user,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "") 
    }
  }).then((response) => {
    return response
  });
};


// Word Cloud
export const getWordCloud = (type: string, workflow_id : string) => {
  const apiUrl = url+"/get_word_cloud";
  const usertoken = { "type": type ,"workflow_id": workflow_id}
  return axios.post(apiUrl, usertoken,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "") 
    }
  }).then((response) => {
    let chartObj = new WordCloud();
    response.data.data.map((dataa) => {
      let dataObj = new WordCloudData(dataa.text, dataa.value);
      chartObj.pushDataSet(dataObj);
    })
    const wordCloud={"wordcloud":chartObj,"msg":response.data.msg}
    return wordCloud
  });
}
