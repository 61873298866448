export class DonutChart{
    labels: string[];
    datasets : Array<DonutData>;
    constructor(labels){
        this.labels = labels;
        this.datasets = new Array();
    }
    pushDataSet(dataobj :DonutData){
        this.datasets.push(dataobj);
    }
}

export class DonutData{
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
    constructor(data,backgroundColor,borderColor,borderWidth){
        this.data = data;
        this.backgroundColor = backgroundColor;
        this.borderColor = borderColor;
        this.borderWidth = borderWidth;
    }
}