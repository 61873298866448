import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import { getTonalityDonut } from '../../Services/ChartServices';
import Spinner from '../Spinner';
import { DonutChart, DonutData } from '../../Models/DonutChart';

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutTonality: React.FunctionComponent<{type :string,workflow_id:string}> = (props) => {

  const [isDataSet, setIsDataSet] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [isSpinner, setIsSpinner] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    getTonalityDonut(props.type, props.workflow_id).then((response) => {
      if (response.data.msg = "success") {
        let data = response.data.data.datasets[0]
        let dataObj = new DonutData(data.data, data.backgroundColor, data.borderColor, data.borderWidth);
        let chartObj = new DonutChart(response.data.data.labels)
        chartObj.pushDataSet(dataObj);
        setIsDataSet(true)
        setIsError(false);
        setIsSpinner(false);
        setData(chartObj)
      }
      else if (response.data.msg = "error") {
        setErrorMessage("error");
      }
    }).catch(err => {
        setIsError(true);
        setErrorMessage("Error");
      })
  }, [])
  
  return <div>
    {isSpinner == true && isError == false && <div style={{ marginTop: "50px", textAlign: "center" }}><Spinner /> </div>}
    {isError == true && <h3 style={{ color: 'red', marginTop: "50px", textAlign: "center" }}> {errorMessage}</h3>}
    {isSpinner == false && isDataSet == true && <Doughnut data={data as any} options={{
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false,
        },
      },
    }} />}</div>
    }
export default DonutTonality   
    