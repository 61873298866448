export class WordCloud{
    data : Array<WordCloudData>;
    constructor(){
        this.data = new Array();
    }
    pushDataSet(dataobj :WordCloudData){
        this.data.push(dataobj);
    }   
}

export class WordCloudData{
    text: string;
    value: number;

    constructor(text,value){
        this.text = text;
        this.value = value;
    }
}
