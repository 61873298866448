import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getUserProfileImage } from '../../Services/Profile';
import { UploadDataService } from '../../Services/WorkflowServices';
import HeaderDash from '../Header/HeaderDash';
import ImportDataText from './ImportDataText';
import UploadData from './UploadData';

const Workflow:React.FunctionComponent<{}> = (props) =>{

  const [isShowUploadCsv, setShowUploadCsv] = useState<boolean>(true);
  const [isShowTable, setShowTable] = useState<boolean>(false);
  // const [isShowAudio, setShowAudio] = useState<boolean>(false);
  const [bodyTableValue, setBodyTable] = useState<Array<any>>([]);
  const [headerTableValue, setHeaderTable] = useState<Array<any>>([]);
  const [keywordsValue,setKeywords]=useState<any>();
  const [profileImage, setProfileImage] = useState<any>("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

  let navigate = useNavigate();
  let {workflow_id}  = useParams();
  
  useEffect(() => {
    getUserProfileImage("").then((response) => {
      setProfileImage(response.profile_image)
    }).catch(err => {
       console.log("error")
    })
}, [])

  const showUploadCsvHandler = (headerValue : any , bodyValue : any,keywordsValue:any, isShowUpload : boolean, isShowtable :boolean ) => {
    setHeaderTable(headerValue);
    setBodyTable(bodyValue);
    setKeywords(keywordsValue);
    setShowUploadCsv(isShowUpload);
    setShowTable(isShowtable);
    // setShowAudio(isShowAudio);
  };

  const importDataTextHandler=(dataObjValue :any )=>{
    UploadDataService(dataObjValue).then((response)=>{ 
      navigate("/projects");
  }).catch(err => {
    console.log("Error");
})
  }

//   const importDataAudioCsvHandler=(dataObjValue :any)=>{
//     UploadDataService(dataObjValue).then((response)=>{ 
//     navigate("/projects");
//   }).catch(err => {
//     console.log("Error");
// })
//   }

   const importDataAudioFilesHandler=()=>{
     navigate("/projects");
   }

  return (
    <div>
      <HeaderDash profileImage={profileImage}/>
      <div className='container'>
        {isShowUploadCsv == true && <UploadData workflow_id={workflow_id} onShowUploadData={showUploadCsvHandler} onImportDataAudioFiles={importDataAudioFilesHandler}/>}
        {(isShowTable == true) && <div style={{paddingTop: "80px"}}><ImportDataText headerTableValue={headerTableValue} onImportDataText={importDataTextHandler} bodyTableValue={bodyTableValue} keywordsValue={keywordsValue} workflow_id={workflow_id} /></div>}
        {/* {(isShowTable == true && isShowAudio == true) && <div style={{paddingTop: "80px"}}><ImportDataAudio headerTableValue={headerTableValue} bodyTableValue={bodyTableValue} workflow_id={workflow_id}keywordsValue={keywordsValue} onImportDataAudioCsv={importDataAudioCsvHandler} /></div>} */}
 </div> 
    </div>
  )
}

export default Workflow