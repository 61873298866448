import React, { useEffect, useState } from 'react'
import { getInsights } from '../../../Services/WorkflowServices';
import Spinner from '../../Spinner';
import Pagination from '../../Pagination';

const TextCsv: React.FunctionComponent<{ workflow_id: string }> = (props) => {

    const [isDataSet, setIsDataSet] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const [numberPage, setNumberPage] = useState<number>(1);
    const [isSpinner, setIsSpinner] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<any>();
    const [dataLenght, setDataLenght] = useState<any>();
    const [isError, setIsError] = useState<boolean>(false);
    const [textValue, setTextValue] = useState<string>();

    useEffect(() => {
        getInsights(props.workflow_id, numberPage).then((data) => {
            if (data.data.msg = "success") {
                setDataLenght(data.data.data.length)
                setIsDataSet(true)
                setIsSpinner(false);
                setIsError(false);
                setNumberPage(data.data.page_count)
                setData(data.data.data);
        }
            else if (data.data.msg = "error") {
                setErrorMessage("Error");
            }
        }).catch(err => {
            setIsError(true);
            setErrorMessage("Error");
        })
    }, [])

    const paginateHandler = (pageNumber) => {
        getInsights(props.workflow_id, pageNumber).then((data) => {
            setNumberPage(data.data.page_count)
            setData(data.data.data);
        }).catch(err => {
            setIsError(true);
            setErrorMessage("Error");
        })
    }
    const handleShowText = (text) => {
        setTextValue(text);

    };
    return (
        <div>
            {/* <div className="form form_search">
                <i className="fa fa-search search_text"></i>
                <input type="text" className="form-control form-input" placeholder="Search..." />
            </div> */}
            <div className="table-responsive1" >
                <table className="table table-hover mb-0">
                    <thead>
                        <tr className='header-chart'>
                            <th>Id</th>
                            <th>Text</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isSpinner == false && isDataSet == true && data.map((lines, index) => {
                            return <tr key={"line_" + index}>
                                <td>{lines.id}</td>
                                <td>
                                    <div style={{ fontWeight: '400',fontSize: '15px'}}> {lines.text.length > 200 ? `${lines.text.substring(0, 200)}...` : lines.text}</div>
                                    <div style={{ display: 'flex', listStyle: 'none' ,width: '100%'}}> <div className='grey'>Topic: <span className="badge badge-pill badge-primary">{lines.topic_label}</span> </div></div>
                                    <div style={{ display: 'flex', listStyle: 'none' ,width: '100%'}}> <div className='grey'>Keywords:{lines.keywords.map((keywords, index) => {
                                        return <span className=" span-text badge badge-pill badge-keywords">{keywords}</span>
                                    })} </div></div>

                                    <div style={{ display: 'flex', listStyle: 'none' ,width: '100%'}}><div className='grey'> Sentiment : <span className={ [lines.sentiment_label == "Negative Sentiment" ? "  span-text badge badge-pill badge-danger " : " ", lines.sentiment_label == "Positive Sentiment" ? "  span-text badge badge-pill badge-success " : " "].join(" ")}>{lines.sentiment_label}</span></div></div>
                                    <div style={{ display: 'flex', listStyle: 'none' ,width: '100%'}}>  <div className='grey'> Emotion:<span className=" span-text badge badge-pill badge-pink">{lines.emotion_label}</span></div></div>
                                    <div style={{ display: 'flex', listStyle: 'none' ,width: '100%'}}>  <div className='grey'>Personnality Traits:<span className=" span-text badge badge-pill badge-pesonnality"> {lines.personality_label}</span></div></div>
                                    <div className='table-analytics'>   <div className='grey'>Communication And Tonality:<span className=" span-text badge badge-pill badge-communication"> {lines.tonality_label}</span></div></div>
                                    <div style={{ display: 'flex', listStyle: 'none' ,width: '100%'}}>   <div className='grey'> Brand Recommendation:<span className=" span-text badge badge-pill badge-brand"> {lines.brand_label} </span></div></div>
                                    <div style={{ color: 'grey', float: 'right' }}><button type="button" data-toggle="modal" data-target="#exampleModal" className=' full-text' onClick={() => { handleShowText(lines.text) }}>Full Text</button></div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            {isSpinner == false && dataLenght != 0 && <Pagination pageCount={numberPage} onPaginate={paginateHandler} />}
            {dataLenght == 0 && isSpinner == false && isError==false && <h3 style={{fontSize:"16px",textAlign:"center"}}> Is List Empty</h3>}
            {isError == true && <h3 style={{ color: 'red',textAlign: 'center' }}> {errorMessage}</h3>}
            {isSpinner == true && isError == false && <div style={{textAlign: 'center', marginTop:"30px"}}><Spinner /></div>}
           
            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {textValue}
                        </div>
                    </div>
                </div>
            </div>
 </div>
    ) 
}

export default TextCsv