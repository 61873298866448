import React, { useEffect, useState } from 'react';

const Pagination: React.FunctionComponent<{ pageCount, onPaginate }> = (props) => {

  const [pageNumbers, setPageNumbers] = useState<any>();
  const [currentPageValue, setCurrentPageValue] = useState<any>(1);
  const [pageValue, setPageValue] = useState<any>();
  const [maxPageLimit, setMaxPageLimit] = useState(3);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const pageNumberLimit = 3;

  useEffect(() => {
    const pages: any = [];

    for (let i = 1; i <= (props.pageCount); i++) {
      pages.push(i);
    }
     setPageNumbers(pages);
  }, [])

  const onPageChange = (value) => {
    setCurrentPageValue(value);
    props.onPaginate(value);
    setPageValue(value)

  }


  const previousPage=()=>{
    if((currentPageValue-1) % pageNumberLimit === 0){
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
  }
  onPageChange(currentPageValue-1);
  }

  const nextPage=()=>{
    if(currentPageValue+1 > maxPageLimit){
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
  }
  onPageChange(currentPageValue+1);
  }

  return pageNumbers ? (
    <>
      <nav className='d-flex justify-content-end'>
        <ul className='pagination'>
         {props.pageCount>3 && <li className='page-item'>
          <button onClick={previousPage} className='page-link' disabled={currentPageValue==1}>
          Prev
              </button>
            </li>}
          {pageNumbers.map(number => (
            <li key={number} className={`page-item ${currentPageValue == number ? 'active' : ''}`}>
           { number <= maxPageLimit  && number > minPageLimit && <a onClick={() => onPageChange(number)} className='page-link'>
                {number}
              </a>}
            </li>
          ))} 
         {props.pageCount>3 &&  <li className='page-item'>
          <button onClick={nextPage} className='page-link' disabled={currentPageValue==props.pageCount}>
           Next
              </button>
            </li>}
        </ul>
      </nav>
    </>
  ) : <></>
}

export default Pagination