import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getBrandOverTime } from '../../Services/ChartServices';
import Spinner from '../Spinner';
import { LineChart, LineData } from '../../Models/LineChart';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'top' as const,
    },
  },
  scales: {
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

const LineBrand: React.FunctionComponent<{ type: string, workflow_id: string }> = (props) => {

  const [isDataSet, setIsDataSet] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [isSpinner, setIsSpinner] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    getBrandOverTime(props.type, props.workflow_id).then((response) => {
      if (response.data.msg = "success") {
        let chartObj = new LineChart(response.data.data.labels)
        response.data.data.datasets.map((data) => {
          let dataObj = new LineData(data.fill, data.label, data.data, data.backgroundColor, data.borderColor);
          chartObj.pushDataSet(dataObj);
        })
        setIsDataSet(true);
        setIsSpinner(false);
        setIsError(false);
        setData(chartObj);
      }
      else if (response.data.msg = "error") {
        setErrorMessage("error");
      }
    })
      .catch(err => {
        setIsError(true);
        setErrorMessage("Error");

      })
  }, [])

  return <div>
    {isSpinner == true && isError == false && <div style={{ marginTop: "50px", textAlign: "center" }}><Spinner /> </div>}
    {isError == true && <h3 style={{ color: 'red', marginTop: "50px", textAlign: "center" }}> {errorMessage}</h3>}

    {isSpinner == false && isDataSet == true && <Line options={options} data={data as any} />}</div>

}
export default LineBrand  