import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { BarChart, BarData } from '../../Models/BarChart';
import { getTopics } from '../../Services/ChartServices';
import Spinner from '../Spinner';

ChartJS.register(CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend);

export const options = {
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'right' as const,
    },
  },
};
 
const Barchart: React.FunctionComponent<{type :string,workflow_id:string}> = (props) => {

   const [isDataSet, setIsDataSet]=useState<boolean>(false);
   const [data, setData]=useState<any>(null);
   const [isSpinner, setIsSpinner] = useState<boolean>(true);
   const [errorMessage, setErrorMessage] = useState<any>();
   const [isError, setIsError] = useState<boolean>(false);

   useEffect(()=>{
    getTopics(props.type,props.workflow_id).then((response)=>{
       if (response.data.msg = "success") {
        let chartObj = new BarChart(response.data.data.labels)
        response.data.data.datasets.map((data) => {
          let dataObj = new BarData(data.label, data.data, data.backgroundColor, data.borderColor);
          chartObj.pushDataSet(dataObj);
        })
           setIsDataSet(true)
           setIsSpinner(false);
           setIsError(false);
           setData(chartObj);
       }
       else if (response.data.msg = "error") {
           setErrorMessage("error");
       }
      })
      .catch(err => {
        setIsError(true);    
        setErrorMessage("Error");

    })

   },[])
   
      return <div>
         {isSpinner == true && isError==false && <div style={{marginTop: "50px", textAlign:"center"}}><Spinner/> </div> }
    {isError==true && <h3 style={{color:'red',marginTop: "50px", textAlign:"center"}}> {errorMessage}</h3>}
       
       {isSpinner == false && isDataSet==true && <Bar options={options} data={data as any} />}</div>
}
export default Barchart 
