import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, Button } from "react-bootstrap";
import CSV from 'csvtojson';
import { Data } from '../../Models/Data';
import ReactAudioPlayer from 'react-audio-player';
import { delete_project_element, get_project_details, get_project_elements, UploadDataService } from '../../Services/WorkflowServices';
import Pagination from '../Pagination';
import Spinner from '../Spinner';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType)

const ProjectDetails: React.FunctionComponent<{}> = (props) => {

    const [selectedOptionValue, setSelectedOption] = useState<string>("");
    const [isDataSet, setIsDataSet] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const [dataDetails, setDataDetails] = useState<any>(null);
    const [isModalUpload, setIsModalUpload] = useState<boolean>(false);
    const [linesData, setLinesData] = useState<any>(null);
    const [headerData, setHeaderData] = useState<any>(null);
    const [isModalSelectTable, setIsModalSelectTable] = useState<boolean>(false);
    const [selectedOptionAudioValue, setSelectedOptionAudio] = useState<any>();
    const [selectedOptionTextValue, setSelectedOptionText] = useState<any>();
    const [selectedOptionDateValue, setSelectedOptionDate] = useState<any>();
    const [selectedOptionUsersValue, setSelectedOptionUsers] = useState<any>();
    const [filesValue, setFilesValue] = useState<File>();
    const [files, setFiles] = useState<any>();
    const [numberPage, setNumberPage] = useState<number>(1);
    const [keywordsValue, setKeywords] = useState<any>("");
    const [idData, setIdData] = useState<string>();
    let { workflow_id } = useParams();
    const [isdataDetails, setIsDataDetails] = useState<any>(null);
    const [isSpinner, setIsSpinner] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<any>();
    const [dataLenght, setDataLenght] = useState<any>();
    const [isError, setIsError] = useState<boolean>(false);
    const [errorFile, setErrorFile] = useState<string>("");
    const [isUploadData, setIsUploadData] = useState<boolean>(false);
    const [errorKeywords, setErrorKeywords] = useState<any>();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isErrors, setIsErrors] = useState<boolean>(false);
    const [message, setMessage] = useState<any>();


    useEffect(() => {
        get_project_elements(workflow_id, numberPage).then((data) => {
            if (data.data.msg = "success") {
                setDataLenght(data.data.data.length)
                setIsDataSet(true)
                setIsSpinner(false);
                setIsError(false);
                setNumberPage(data.data.page_count)
                setData(data.data.data);
            }
            else if (data.data.msg = "error") {
                setErrorMessage("error");
            }
        }
        ).catch(err => {
            setIsError(true);
            setErrorMessage("Error");
        });

        get_project_details(workflow_id).then((data) => {
            setDataDetails(data)
            setIsDataDetails(true)
            if (data.category == "audio") {
                if (data.sub_category == "csv_file") {
                    setSelectedOption("AudioCsv")
                } else {
                    setSelectedOption("Audio")
                }
            } else {
                setSelectedOption("TextCsv")
            }
        }).catch(err => {
            setErrorMessage("Error")
        });

    }, [])

    const handleCloseModalUpload = () => setIsModalUpload(false);

    const handleShowModalUpload = () => {
          setIsModalUpload(true)     
    };

    const handleCloseModalSelectTable = () => setIsModalSelectTable(false);

    const handleShowModalelectTable = () => {
        setIsModalSelectTable(true)
    };

    const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const element = event.target as HTMLSelectElement;
        setSelectedOption(element.value);
    }

    const fileChange = (e: any) => {
        setFilesValue(e.target.files[0]);
        setIsUploadData(true)
    }
    const handleChangeText = (event: React.FormEvent<HTMLSelectElement>) => {
        const element = event.target as HTMLSelectElement;
        setSelectedOptionText(element.value);
    }

    const handleChangeAudio = (event: React.FormEvent<HTMLSelectElement>) => {
        const element = event.target as HTMLSelectElement;
        setSelectedOptionAudio(element.value);
    }

    const handleChangeDate = (event: React.FormEvent<HTMLSelectElement>) => {
        const element = event.target as HTMLSelectElement;
        setSelectedOptionDate(element.value);
    }

    const handleChangeUsers = (event: React.FormEvent<HTMLSelectElement>) => {
        const element = event.target as HTMLSelectElement;
        setSelectedOptionUsers(element.value);
    }


    const uploadCsv = (e: any) => {
        setErrorFile("")
        setErrorKeywords("")
        if (!filesValue) {
            setErrorFile("This field cannot be empty");
        }
        if (!keywordsValue) {
            setErrorKeywords("This field cannot be empty")
        }
        else {
            let linesValue: string[] = [];
            let readerValue: FileReader = new FileReader();
            let fileValue: any = filesValue;
            let headerValue: string[] = [];

            readerValue.readAsText(fileValue);
            readerValue.onload = (e: any) => {
                let csvValue: any = readerValue.result;
                let parsedCsvLines: any = CSV({
                    noheader: true,
                    output: "csv",
                })
                    .fromString(csvValue)
                    .then((csvRow: any) => {
                        let allTextLinesValue: string[] = csvRow;
                        let headersValue: string = allTextLinesValue[0];
                        let dataValue: any = headersValue;
                        for (let j = 0; j < headersValue.length; j++) {
                            headerValue.push(dataValue[j]);
                        }
                        setHeaderData(headerValue);
                        let textLinesValue: number = (allTextLinesValue).length;
                        let rowsValue: string[] = [];
                        for (let i = 1; i < textLinesValue; i++) {
                            rowsValue.push(allTextLinesValue[i]);
                        }
                        linesValue = rowsValue;
                        setLinesData(rowsValue);
                        handleShowModalelectTable();
                        setIsModalUpload(false);
                    });
            }
        }
    }

    const uploadAudio = () => {
        setIsModalUpload(false);
        get_project_elements(workflow_id, numberPage).then((data) => {
            setIsDataSet(true)
            setFiles("")
            setNumberPage(data.data.page_count)
            setData(data.data.data)
        }).catch(err => {
            // setIsError(true);
            // setErrorMessage("Error");
        });
    }

    const getText = (index: any) => {
        for (let i = 0; i < linesData.length; i++) {
            const elementValue: any = linesData[i];
            for (let j = 0; j < elementValue.length; j++) {
                if (j == selectedOptionTextValue) {
                    return elementValue[j];
                }
            }
        }
    }

    const getAudio = (index: any) => {
        for (let i = 0; i < linesData.length; i++) {
            const elementValue: any = linesData[i];
            for (let j = 0; j < elementValue.length; j++) {
                if (j == selectedOptionAudioValue) {

                    return elementValue[j];
                }
            }
        }
    }

    const getDate = (index: any) => {
        for (let i = 0; i < linesData.length; i++) {
            const elementValue: any = linesData[i];
            for (let j = 0; j < elementValue.length; j++) {
                if (j == selectedOptionDateValue) {

                    return elementValue[j];
                }
            }
        }
    }

    const getUsers = (index: any) => {
        for (let i = 0; i < linesData.length; i++) {
            const elementValue: any = linesData[i];
            for (let j = 0; j < elementValue.length; j++) {
                if (j == selectedOptionUsersValue) {

                    return elementValue[j];
                }
            }
        }
    }

    const save = (e: any) => {

        let objectValue: any;
        let dataObjValue = new Data();
        let linesDataValue: any[] = [];
        for (let i = 0; i < linesData.length; i++) {
            const elementValue: any = linesData[i];
            for (let j = 0; j < elementValue.length; j++) {
                if (dataDetails.category == "audio") {
                    objectValue = {
                        "audio": elementValue[selectedOptionAudioValue],
                        "user": elementValue[selectedOptionUsersValue],
                        "date": elementValue[selectedOptionDateValue]
                    }

                } else {
                    objectValue = {
                        "text": elementValue[selectedOptionTextValue],
                        "user": elementValue[selectedOptionUsersValue],
                        "date": elementValue[selectedOptionDateValue]
                    }
                }

            }
            linesDataValue.push(objectValue);
        }
        dataObjValue.workflow_id = dataDetails._id;
        dataObjValue.data = linesDataValue;
        dataObjValue.user_id = "";
        dataObjValue.category = dataDetails.category;
        dataObjValue.sub_category = "csv_file";
        dataObjValue.keywords = keywordsValue.split(',');

        UploadDataService(dataObjValue).then((response) => {
            setIsModalSelectTable(false);
            setIsSuccess(true);
            setMessage("Uploaded Successfully");
            get_project_elements(workflow_id, numberPage).then((data) => {
                setIsDataSet(true)
                setNumberPage(data.data.page_count)
                setData(data.data.data)
            }).catch(err => {
                setIsError(true);
                setErrorMessage("Error");
            });
        }).catch(err => {
            setIsModalSelectTable(false);
            setIsErrors(true);
            setMessage("Error");
        });
        setTimeout(() => {
            setIsErrors(false);
            setIsSuccess(false)
        }, 3000);

    }
    const paginateHandler = (pageNumber) => {
        get_project_elements(workflow_id, pageNumber).then((data) => {
            setIsDataSet(true)
            setNumberPage(data.data.page_count)
            setData(data.data.data)
        })
    }
    const deleteData = () => {
        delete_project_element(workflow_id, idData).then((data) => {
            if (data.msg = "success") {
                setIsSuccess(true);
                document.getElementById("closeRemoveModal")?.click();
                setMessage("Project Deleted Successfully");
                get_project_elements(workflow_id, numberPage).then((data) => {
                    setIsDataSet(true)
                    setNumberPage(data.data.page_count)
                    setData(data.data.data)
                }).catch(err => {
                    setIsError(true);
                    setErrorMessage("Error");
                });
            }
            else if (data.msg = "error") {
                document.getElementById("closeRemoveModal")?.click();
                setIsErrors(true);
                setMessage("Error");
            }
        }).catch(err => {
            document.getElementById("closeRemoveModal")?.click();
            setIsErrors(true);
            setMessage("Error");
        });
        setTimeout(() => {
            setIsErrors(false);
            setIsSuccess(false)
        }, 3000);
    }
    return isdataDetails ? (
        <div>
            <div className="page-content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-table">
                                <div className="card-table-body">
                                    <div className='title_project'>
                                        <h1 className="mt-0 header-title ">Project Name: {dataDetails && dataDetails.workflow_name}</h1>
                                    </div>
                                    <div id="collapse1">
                                        <div className='d-flex justify-content-end' style={{ marginBottom: "5px" }}><Button color="btn btn-primary" onClick={handleShowModalUpload}><i className="fas fa-plus"></i></Button></div>
                                        {isSuccess == true && <div className="alert alert-success" role="alert">
                                            {message}
                                        </div>}
                                        {isErrors == true && <div className="alert alert-danger" role="alert">
                                            {message}
                                        </div>}
                                        <div className="table-responsive">

                                            <table className="table mb-0">
                                                <thead className="theadColor">
                                                    <tr>
                                                        <th>Id</th>
                                                        {dataDetails.category == "text" && <th>Text</th>}
                                                        {dataDetails.category == "audio" && <th>Audio</th>}
                                                        <th>Date</th>
                                                        <th>User</th>
                                                        <th>Status</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isSpinner == false && isDataSet == true && data.map((lines, index) => {
                                                        return <tr key={"line_" + index}>
                                                            <td>{lines._id}</td>
                                                            {dataDetails.category == "text" && <td>
                                                                {lines.text.length > 200 ? `${lines.text.substring(0, 200)}...` : lines.text}</td>}
                                                            {dataDetails.category == "audio" && <td><ReactAudioPlayer src={lines.audio} style={{ marginBottom: "15px", height: "40px" }} controls /></td>}
                                                            <td>{lines.date}</td>
                                                            <td>{lines.user}</td>
                                                            <td><span className={[lines.status == "processing" ? "badge badge-pill badge-orange" : " ", lines.status == "ready" ? "badge badge-pill badge-primary" : " ", lines.status == "failed" ? "badge badge-pill badge-danger" : " "].join(" ")}>{lines.status}</span></td>
                                                            <td><button className="btn btn-danger btn-sm" data-toggle="modal" data-target="#delete" disabled={lines.status == "processing"} onClick={() => { setIdData(lines._id) }}><i className="fas fa-trash"></i></button></td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        {isSpinner == false && dataLenght != 0 && <Pagination pageCount={numberPage} onPaginate={paginateHandler} />}
                                        {dataLenght == 0 && isSpinner == false && isError == false && <h3 style={{ fontSize: "16px" }}> Is List Empty</h3>}
                                        {isError == true && <h3 style={{ color: 'red' }}> {errorMessage}</h3>}
                                        {isSpinner == true && isError == false && <Spinner />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={isModalUpload} onHide={handleCloseModalUpload}>
                    <Modal.Header >
                        <Modal.Title>Upload Data</Modal.Title>
                        <button type="button" className="close" data-dismiss="modal" onClick={() => { setIsModalUpload(false); setFiles(""); setKeywords(""); setErrorKeywords("") }} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row '>
                            <div className='col-12'>
                                <div className="form-group">
                                    <label htmlFor="keywords" className="form-label">Keywords</label>
                                    <input type="text" onChange={(e) => { setKeywords(e.target.value); }} className="form-control" id="keywords" placeholder="Ex: key1, key2" />
                                    {errorKeywords != "" && <span className="text-danger small">{errorKeywords}</span>}

                                </div>
                                <div className="form-group">
                                    <select className="form-control" id="searchType" onChange={e => handleChange(e)} value={selectedOptionValue} disabled>
                                        <optgroup label="Text">
                                            <option value="TextCsv">File Csv</option>
                                        </optgroup>
                                        <optgroup label="Audio">
                                            <option value="Audio">Audio</option>
                                        </optgroup>
                                    </select>
                                </div>
                                {(selectedOptionValue == "TextCsv") && <div className="input-group">
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="inputGroupFile01"
                                            accept='.csv'
                                            onChange={(e) => {
                                                fileChange(e)
                                                setErrorFile("")
                                            }}
                                            aria-describedby="inputGroupFileAddon01" />
                                        <label className="custom-file-label" htmlFor="inputGroupFile01">
                                            Choose File CSV
                                        </label>
                                    </div>
                                </div>}
                                {(selectedOptionValue == "Audio") && <div style={{ maxHeight: "150px", overflow: 'auto' }}>
                                    <FilePond
                                        files={files}
                                        allowMultiple={true}
                                        // allowReorder={true}
                                        onupdatefiles={(filess) => {
                                            setFiles(filess)
                                            setIsUploadData(true)
                                        }}
                                        credits={false}
                                        // allowFileEncode={true}
                                        acceptedFileTypes={['audio/*']}
                                        onprocessfiles={() => {
                                            uploadAudio();
                                        }}
                                        server={{
                                            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                                const formData = new FormData();

                                                formData.append("audio", file);
                                                formData.append("extension", file.type);
                                                formData.append("user", "user");
                                                formData.append("date", new Date().toLocaleDateString());
                                                formData.append("category", "audio")
                                                formData.append("sub_category", "audio")
                                                formData.append("keywords", keywordsValue.split(','))
                                                formData.append("workflow_id", workflow_id as any)

                                                const request = new XMLHttpRequest();

                                                request.open('POST', 'https://api.coralytics.com/upload_data_v2');
                                                request.setRequestHeader(
                                                    "Authorization", "Bearer " + JSON.parse(localStorage.getItem("usertoken") || "")
                                                );
                                                request.upload.onprogress = (e) => {
                                                    progress(e.lengthComputable, e.loaded, e.total);
                                                };

                                                request.onload = function () {
                                                    setErrorKeywords("");
                                                    if (!keywordsValue) {
                                                        setErrorKeywords("This field cannot be empty")
                                                    }
                                                    if (request.status >= 200 && request.status < 300) {
                                                        load(request.responseText);
                                                        setIsSuccess(true);
                                                        setMessage("Uploaded Successfully");
                                                    } else {
                                                        setIsErrors(true);
                                                        setMessage("Error");
                                                        error('error');
                                                    }
                                                    setTimeout(() => {
                                                        setIsErrors(false);
                                                        setIsSuccess(false)
                                                    }, 3000);
                                                };

                                                request.send(formData);
                                                return {
                                                    abort: () => {
                                                        request.abort();
                                                        abort();
                                                    },
                                                };
                                            }
                                        }}
                                        name="files"
                                        labelIdle='Drag and Drop your files or <span class="filepond--label-action">Browse</span>'
                                    />
                                </div>
                                }
                                {errorFile != "" && <span className="text-danger small">{errorFile}</span>}
                            </div>
                        </div>
                        <br />
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            {(selectedOptionValue == "TextCsv" ) && <div className='col-12'>
                                <button onClick={(e) => {
                                    uploadCsv(e);
                                }} className="btn btn-primary" disabled={isUploadData == false}>Upload</button>
                            </div>}
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Modal select table*/}
                <Modal show={isModalSelectTable} onHide={handleCloseModalSelectTable} size="lg" >
                    <Modal.Header >
                        <Modal.Title>Select Data Fields</Modal.Title>
                        <button type="button" className="close" data-dismiss="modal" onClick={() => { setIsModalSelectTable(false) }} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div  style={{ maxHeight: "300px", overflow: 'auto' }}>
                        <div className="row">
                            <div className="col-12">
                                <h6 style={{ color: "#707070" }}>Map the CSV columns with the following fields</h6>
                                <div id="collapse1" >
                                    <div className="table-responsive1">
                                        {headerData && linesData && <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{width :231}}>
                                                        {/* {dataDetails.category == "audio" && <select className="form-control" id="searchType" onChange={e => handleChangeAudio(e)} value={selectedOptionAudioValue}>
                                                            <option value="">Select Audio</option>
                                                            {headerData.map((audio, index) => <option value={index} key={"headerAudio_" + index} >{audio}</option>)}
                                                        </select>} */}

                                                        {dataDetails.category == "text" && <select style={{width :231}} className="form-control" id="searchType" onChange={e => handleChangeText(e)} value={selectedOptionTextValue}>
                                                            <option value="">Select Text</option>
                                                            {headerData.map((text, index) => <option value={index} key={"headerText_" + index} >{text}</option>)}
                                                        </select>}
                                                    </th >
                                                    <th style={{width :231}}><select style={{width :231}}   className="form-control" id="searchType" onChange={e => handleChangeDate(e)} value={selectedOptionDateValue}>
                                                        <option value="">Select Date</option>
                                                        {headerData.map((date, index) => <option value={index} key={"headerDate_" + index} >{date}</option>)}
                                                    </select></th>
                                                    <th style={{width :231}}><select style={{width :231}}  className="form-control" id="searchType" onChange={e => handleChangeUsers(e)} value={selectedOptionUsersValue}>
                                                        <option value="">Select Users</option>
                                                        {headerData.map((user, index) => <option value={index} key={"headerUsers_" + index} >{user}</option>)}
                                                    </select></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {linesData.map((lines, index) => {
                                                    return <tr key={"line_" + index}>
                                                        {/* {dataDetails.category == "audio" && <td key={"columnAudio_" + index}>{getAudio(index)}</td>} */}
                                                        {dataDetails.category == "text" && <td style={{width :231}} key={"columnText_" + index}>{getText(index)}</td>}
                                                        <td style={{width :231}} key={"columnDate_" + index}>{getDate(index)}</td>
                                                        <td style={{width :231}} key={"columnUsers_" + index}>{getUsers(index)}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-md-end  mt-4">
                            <Button color="primary" onClick={(e) => { save(e) }} >Import</Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <div className="modal fade" id="delete" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title" id="exampleModalLabel">Confirmation</h6>
                                <button type="button" className="close" id="closeRemoveModal" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this function?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
                                <button type="button" className="btn btn-danger" onClick={() => { deleteData() }} >Yes</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    ) : <></>
}

export default ProjectDetails
