import { Navigate } from "react-router-dom";
import { isLogged } from "../Services/Authentication.service";
 
const AuthGuard =({children})=>{

  if(!isLogged()){
    return <Navigate to="/login"/>
  }
  return children
}
 
 
export default AuthGuard;