import React, { useEffect , useState } from 'react'
import { getUserProfileImage } from '../../Services/Profile';
import { get_project_details } from '../../Services/WorkflowServices';
import HeaderDash from '../Header/HeaderDash';
import Sidebar from '../Sidebar/Sidebar';
import { Outlet , useParams } from 'react-router-dom'

const Layout: React.FunctionComponent<{}> = () => {

    let {workflow_id} :any = useParams();
    const [dataDetails, setDataDetails] = useState<any>("");
    const [profileImage, setProfileImage] = useState<any>("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

   useEffect(() => {
       get_project_details(workflow_id).then((data) => {
           setDataDetails(data.status);
       }).catch(err => {
           console.log("Error");
       });
       getUserProfileImage("").then((response) => {
           setProfileImage(response.profile_image)
       }).catch(err => {
          console.log("Error")
       })
   }, [])
   
  return (
    <>
    <HeaderDash profileImage={profileImage}/> 
    <Sidebar profileImage={profileImage} statusProject={dataDetails} workflow_id={workflow_id} />
    <Outlet/>
    </>
  )
}

export default Layout