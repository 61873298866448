import React, { useEffect, useState } from 'react'
import CSV from 'csvtojson';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType)

const AddUploadData: React.FunctionComponent<{ onShowUploadData: any, onImportDataAudioFiles: any, workflow_id: any, dataDetails: any }> = (props) => {

    const [filesValue, setFilesValue] = useState<File>();
    const [files, setFiles] = useState<any>([]);
    const [selectedOptionValue, setSelectedOption] = useState<string>("Text");
    const [keywordsValue, setKeywords] = useState<any>("");
    const [isDataSet, setIsDataSet] = useState<boolean>(false);
    const [isUploadData, setIsUploadData] = useState<boolean>(false);
    const [errorKeywords, setErrorKeywords] = useState<any>();

    useEffect(() => {
        setIsDataSet(true)
        if (props.dataDetails.category == "audio") {
            if (props.dataDetails.sub_category == "csv_file") {
                setSelectedOption("AudioCsv")
            } else {
                setSelectedOption("Audio")
            }
        } else {
            setSelectedOption("TextCsv")
        }

    }, [])
    const fileChange = (e: any) => {
        setFilesValue(e.target.files[0]);
        setIsUploadData(true)
    }

    const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const element = event.target as HTMLSelectElement;
        setSelectedOption(element.value);
    }

    const uploadCsv = (e: any) => {

        let headerValue: string[] = [];
        let linesValue: string[] = [];
        let readerValue: FileReader = new FileReader();
        let fileValue: any = filesValue;
        const isShowTable: boolean = true;
        setErrorKeywords("");
        const isShowUpload: boolean = false;
        readerValue.readAsText(fileValue);
        readerValue.onload = (e: any) => {
            let csvValue: any = readerValue.result;
            let parsedCsvLines: any = CSV({
                noheader: true,
                output: "csv",
            })
                .fromString(csvValue)
                .then((csvRow: any) => {
                    let allTextLinesValue: string[] = csvRow;
                    let headersValue: string = allTextLinesValue[0];
                    let dataValue: any = headersValue;
                    for (let j = 0; j < headersValue.length; j++) {
                        headerValue.push(dataValue[j]);
                    }
                    let textLinesValue: number = (allTextLinesValue).length;
                    let rowsValue: string[] = [];
                    for (let i = 1; i < textLinesValue; i++) {
                        rowsValue.push(allTextLinesValue[i]);
                    }
                    linesValue = rowsValue;
                    if (!keywordsValue) {
                    setErrorKeywords("This field cannot be empty")
                    }
                    if (selectedOptionValue == "Text") {
                        props.onShowUploadData(headerValue, linesValue, keywordsValue, isShowUpload, isShowTable);
                    } else {
                        props.onShowUploadData(headerValue, linesValue, keywordsValue, isShowUpload, isShowTable);
                    }
                });
        }
    }
    
    const uploadAudio = () => {
        props.onImportDataAudioFiles();
    }

    return isDataSet ? (
        <div>
            <div className="row ">
                <div className="col-12 ">
                    <div className="card-workflow">
                    <div className="card-workflow-body justify-content-center align-items-center">
                            <h1 className="mt-0 header-title">Select Data Fields</h1>
                            <h6 className='upload-data'>Upload Data</h6>
                            <br />
                            <div className='row '>
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label htmlFor="keywords" className="form-label">Keywords</label>
                                        <input type="text" onChange={(e) => { setKeywords(e.target.value); }} className="form-control" id="keywords" placeholder="Ex: key1, key2" />
                                        {errorKeywords!="" && <span className="text-danger small">{errorKeywords}</span>}
                                    </div>
                                    <div className="form-group">
                                        <select className="form-control" id="searchType" onChange={e => handleChange(e)} value={selectedOptionValue} disabled>
                                            <optgroup label="Text">
                                                <option value="TextCsv">File Csv</option>
                                            </optgroup>
                                            <optgroup label="Audio">
                                                <option value="Audio">Audio</option>
                                            </optgroup>
                                        </select>
                                    </div>
                                    {(selectedOptionValue == "TextCsv") && <div className="input-group">
                                        <div className="custom-file">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="inputGroupFile01"
                                                accept='.csv'
                                                onChange={(e) => fileChange(e)}
                                                aria-describedby="inputGroupFileAddon01" />
                                            <label className="custom-file-label" htmlFor="inputGroupFile01">
                                                Choose File CSV
                                            </label>
                                        </div>
                                    </div>}
                                    {(selectedOptionValue == "Audio") && <div style={{ maxHeight: "150px", overflow: 'auto' }}>
                                        <FilePond
                                            files={files}
                                            allowMultiple={true}
                                            // allowReorder={true}
                                             onupdatefiles={(filess) => {
                                                 setFiles(filess)
                                                 setIsUploadData(true)
                                              }}
                                             credits={false}
                                            // allowFileEncode={true}
                                            acceptedFileTypes={['audio/*']}
                                            onprocessfiles={()=>{
                                                uploadAudio();
                                            }}
                                            server={{
                                                process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                                    const formData = new FormData();
                                                    formData.append("audio", file);
                                                    formData.append("extension", file.type);
                                                    formData.append("user", "user");
                                                    formData.append("date", new Date().toLocaleDateString());
                                                    formData.append("category","audio")
                                                    formData.append("sub_category", "audio")
                                                    formData.append("keywords",keywordsValue.split(','))
                                                    formData.append("workflow_id",props.workflow_id)
                                              
                                                    const request = new XMLHttpRequest();
                                                    request.open('POST', 'https://api.coralytics.com/upload_data_v2');
                                                    request.setRequestHeader(
                                                        "Authorization", "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "")
                                                    );
                                                    request.upload.onprogress = (e) => {
                                                        progress(e.lengthComputable, e.loaded, e.total);
                                                    };

                                                    request.onload = function () {
                                                        setErrorKeywords("");
                                                        if (!keywordsValue) {
                                                        setErrorKeywords("This field cannot be empty")
                                                        }
                                                        if (request.status >= 200 && request.status < 300) {
                                                            load(request.responseText);
                                                        } else {
                                                            error('error');
                                                        }
                                                    };
                                                    request.send(formData);
                                                    return {
                                                        abort: () => {
                                                            request.abort();
                                                            abort();
                                                        },
                                                    };
                                                }
                                            }}
                                            name="files"
                                            labelIdle='Drag and Drop your files or <span class="filepond--label-action">Browse</span>'
                                        />
                                    </div>
                                    }
                                    <br/>
                                </div>
                               
                            </div>
                            <div className='rows'>
                                {(selectedOptionValue == "TextCsv") && <div className='col-12'>
                                    <button type="button" onClick={(e) => {
                                        uploadCsv(e);
                                    }} className='btn btn-primary' disabled={isUploadData==false}>Upload</button>
                                </div>}
                               
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    ) : <></>
}

export default AddUploadData