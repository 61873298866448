import React, { useState } from 'react'
import { Link, useNavigate} from 'react-router-dom';
import Header from '../Header/Header';
import { getCurrentUser, login } from '../../Services/Authentication.service';

const Login:React.FunctionComponent<{}> = (props) =>{

    const [emailValue, setEmail] = useState<string>(""); 
    const [errorEmail, setErrorEmail] = useState<string>("");
    const [passwordValue, setPassword] = useState<string>(""); 
    const [errorPassword, setErrorPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<any>();
    const [isError, setIsError] = useState<boolean>(false); 
    let navigate = useNavigate();
    
    const loginUser = (e :any) => {
        const user ={
            "email": emailValue ,
            "password": passwordValue
        }
        e.preventDefault();
        if (!emailValue || !passwordValue) {
            setErrorEmail("This field cannot be empty");
            setErrorPassword("This field cannot be empty");
        }
        else {
            login(user).then(res => {
                if(res.msg="success" && res.token){
                   const user = getCurrentUser();
                    if(user.role="admin"){
                        setIsError(false);
                        navigate('/projects')
                    }else if(user.role="user"){
                        navigate('/projectDetails')
                    }
                   
                }else{
                    setIsError(true);
                    setErrorMessage("Invalid Credentials");               
                }
            }) 
            .catch(err => {
                setIsError(true);
                setErrorMessage("Error");
            })
            setTimeout(() => {   
                setIsError(false);
                }, 3000);
        }
    };
  return (
    <div>   
    <Header/>
           <div className="wrapper-page justify-content-center align-items-center">
            <div className=" login">
                <div className="card-body form-container">
                <div className="text-center m-0">
                        <Link to="/login" className="logo logo-admin"><img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"   alt="logo" /></Link>
                        <h3 className="title_login">
                            Member Login
                    </h3></div>
                    <div >
                    {isError==true && <div className="alert alert-danger m-1" role="alert">
                                   {errorMessage}
                                </div>}
                        <form onSubmit={loginUser} className="form-horizontal m-t-30" action="/">

                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" className="form-control" id="email"  value={emailValue}
                                        onChange={(e :any) => {
                                            setEmail(e.target.value);
                                            setErrorEmail("");
                                        }} placeholder="Enter Email" />
                                        {errorEmail!="" && <span className="text-danger small">{errorEmail}</span>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="userpassword">Password</label>
                                <input type="password" className="form-control" id="userpassword" placeholder="Enter password" 
                                value={passwordValue}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setErrorPassword("");
                                }} />
                               {errorPassword!="" && <span className="text-danger small">{errorPassword}</span>}
                            </div>

                            <div className="form-group row m-t-20">
                                <div className="col-sm-6">
                                
                                </div>
                                <div className="col-sm-6 text-right">
                                    <button className="btn btn-login" type="submit">Log In</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login
