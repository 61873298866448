import axios from "axios";
import { Data } from "../Models/Data";
import { Workflow } from "../Models/Workflow";
import { getUserId } from "./Authentication.service";

const url = "https://api.coralytics.com";
// Service Create WorkFlow:
export const CreateWorkflowService = (workflowObject: Workflow) => {
    const apiUrl=url+"/create_workflow";
    workflowObject.user_id="";
    return axios.post(apiUrl,workflowObject,{
      headers: {
        "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "")
      }
    }).then((response) => {
    return response.data;
    })
  }
  
// Service Import Csv:  
  export const UploadDataService = (objectData: Data) => {
    const apiUrl=url+"/upload_data";
    objectData.user_id="";
    return axios.post(apiUrl,objectData,{
      headers: {
        "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "")
      }
    }).then((response) => {
        return response.data;
    })
  }

  //table Highlight
  export const getInsights = (workflow_id,numberpage) => {
    const apiUrl=url+"/get_data_insights";
    const user={"workflow_id": workflow_id,"page":numberpage}
    return axios.post(apiUrl,user,{
      headers: {
        "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "")
      }
    }).then((response) => {
      const dataobj =response
         return dataobj
    }); 
  }
  
//Get Projects
export const getProjects = (numberpage) => {
  const apiUrl=url+"/get_projects";
  const user={"page":numberpage}
  return axios.post(apiUrl,user,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "")
    }
  }).then((response) => {
    const dataobj =response
       return dataobj
  }); 
}

export const get_project_elements = (project_id,numberpage) => {
  const apiUrl=url+"/get_project_elements";
  const request={"project_id": project_id,"page": numberpage}
  return axios.post(apiUrl,request,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "")
    }
  }).then((response) => {
    const dataobj =response
       return dataobj
  }); 
}

//Delete Project element
export const delete_project_element = (project_id,project_element_id) => {
  const apiUrl=url+"/delete_project_element";
  const request={"workflow_id":project_id,"project_element_id": project_element_id}
  return axios.post(apiUrl,request,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "")
    }
  }).then((response) => {
    const dataobj =response.data
       return dataobj
  }); 
}

//Delete Project
export const delete_project = (project_id,project_name) => {
  const apiUrl=url+"/remove_project";
  const request={"project_name":project_name,"project_id": project_id}
  return axios.post(apiUrl,request,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "")
    }
  }).then((response) => {
    const dataobj =response.data
       return dataobj
  }); 
}

export const get_project_details = (project_id) => {
  const apiUrl=url+"/get_project_details";
  const request={"project_id":project_id,}
   return axios.post(apiUrl,request,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "")
    }
  }).then((response) => {
     const dataobj =response.data.data
        return dataobj
   }); 
}
