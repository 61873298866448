import { Navigate } from "react-router-dom";
import { getCurrentUser, isLogged } from "../Services/Authentication.service";
 
const PublicPagesGuard =({children})=>{
  
const user : any = getCurrentUser(); 

  if(isLogged()){
    switch (user.role) {
      case "admin":
        return <Navigate to="/projects"/>; break;
      case "user":
        return <Navigate to="/projectDetails"/>; break;
    }
  }
  return children
}
 
export default PublicPagesGuard;