import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { get_project_details } from '../../Services/WorkflowServices';

const HeaderFilter: React.FunctionComponent<{totalData :number}> = (props) => {


    return (
        <div className="topbarfilters" style={{ marginTop: "70px" }}>
            <div  id="navfilter">
            <ul style={{paddingLeft: '0px'}}>
                <li> <p className='title_total'> Total  :  {props.totalData} </p></li>
            </ul> 
            {/* <ul style={{position: 'fixed',right: '20px'}}>
                <li><i className="fas fa-calendar" style={{color :'gray',marginRight:"5px"}}></i>Filter by Date</li>
            </ul>  */}
            </div>
        </div>
    ) 
}

export default HeaderFilter