export class BarChart{
    labels: string[];
    datasets : Array<BarData>;
    constructor(labels){
        this.labels = labels;
        this.datasets = new Array();
    }
    pushDataSet(dataobj :BarData){
        this.datasets.push(dataobj);
    }
}

export class BarData{
    label : string;
    data: number[];
    backgroundColor: string[];
    borderColor: string[];

    constructor(label,data,backgroundColor,borderColor){
        this.label = label;
        this.data = data;
        this.backgroundColor = backgroundColor;
        this.borderColor = borderColor;
    }
}
