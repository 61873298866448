import React from 'react'

const Header: React.FunctionComponent<{}> = (props) => {

  return (
    <div className="topbar" >
    <nav className="navbar-custom" style={{background:'rgb(5, 68, 104)'}}>
        <ul className="list-inline menu-left mb-0">
            <li className="hide-phone list-inline-item ">
                {/* <a className="title_dash" href='/projects' id="now_routing" style={{color:'white'}}> BrandForce</a> */}
               <img src="/assets/images/Brandforce.png" alt="" width={'180px'} height={'80px'} style={{marginLeft:'10px'}}/>
            </li>      
        </ul>
    </nav>
</div>
  )
}

export default Header