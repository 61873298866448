import ReactWordcloud, { Word } from "react-wordcloud";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import { getWordCloud } from "../../Services/ChartServices";
import { useEffect, useState } from "react";
import Spinner from "../Spinner";


const WordCloud: React.FunctionComponent<{type :string, workflow_id:string}> = (props) => {

  // const words : any = WordCloudService();
  const [isDataSet, setIsDataSet]=useState<boolean>(false);
  const [data, setData]=useState<any>(null);
  const [isSpinner, setIsSpinner] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [isError, setIsError] = useState<boolean>(false);

     useEffect(()=>{
      let run: boolean =false;
      if(!run){
      getWordCloud(props.type,props.workflow_id).then(response=>{
        if (response.msg = "success") {
        setIsSpinner(false);
        setIsError(false);
        setIsDataSet(true)
        setData(response.wordcloud)
        return ()=>{
          run=true;
        }
      }
      else if (response.msg = "error") {
        setErrorMessage("error");
      }})
      .catch(err => {
         setIsError(true);
         setErrorMessage("Error");
        })}
     },[setIsDataSet,setData])

  return <div>
  {isSpinner == true && isError == false && <div style={{ marginTop: "50px", textAlign: "center" }}><Spinner /> </div>}
  {isError == true && <h3 style={{ color: 'red', marginTop: "50px", textAlign: "center" }}> {errorMessage}</h3>}

  {isSpinner == false && isDataSet == true &&  <div className="wordcloud">
      <ReactWordcloud options={{
        colors: ['#3961C9','#E1C371','#E88CAF','#A16D39','#608373','#A66A6E','#715F9B','#3CB991'],
        enableTooltip: true,
        deterministic: false,
        fontFamily: "impact",
        fontSizes: [16, 40],
        fontStyle: "normal",
        fontWeight: "400",
        padding: 1,
        rotations: 3,
        rotationAngles: [0, 90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 1000
      }} words={data.data as any} />
    </div> }</div>
 
}
export default WordCloud;