export class LineChart{
    labels: string[];
    datasets : Array<LineData>;
    constructor(labels){
        this.labels = labels;
        this.datasets = new Array();
    }
    pushDataSet(dataobj :LineData){
        this.datasets.push(dataobj);
    }
}

export class LineData{
    fill :boolean;
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
    constructor(fill,label,data,backgroundColor,borderColor){
        this.fill=fill;
        this.label=label;
        this.data = data;
        this.backgroundColor = backgroundColor;
        this.borderColor = borderColor;
    }
}