import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { Data } from '../../Models/Data';

const ImportDataText: React.FunctionComponent<{ onImportDataText: any, bodyTableValue: any, workflow_id: any,keywordsValue:any, headerTableValue: any }> = (props) => {

    const [selectedOptionTextValue, setSelectedOptionText] = useState<any>();
    const [selectedOptionDateValue, setSelectedOptionDate] = useState<any>();
    const [selectedOptionUsersValue, setSelectedOptionUsers] = useState<any>()
    const [isUploadData, setIsUploadData] = useState<boolean>(false);

    const handleChangeText = (event: React.FormEvent<HTMLSelectElement>) => {
        const element = event.target as HTMLSelectElement;
        setSelectedOptionText(element.value);
        setIsUploadData(true);
    }

    const handleChangeDate = (event: React.FormEvent<HTMLSelectElement>) => {
        const element = event.target as HTMLSelectElement;
        setSelectedOptionDate(element.value);
        setIsUploadData(true);
    }

    const handleChangeUsers = (event: React.FormEvent<HTMLSelectElement>) => {
        const element = event.target as HTMLSelectElement;
        setSelectedOptionUsers(element.value);
        setIsUploadData(true);
    }

    const getText = (index: any) => {
        for (let i = 0; i < props.bodyTableValue.length; i++) {
            const elementValue: any = props.bodyTableValue[i];
            for (let j = 0; j < elementValue.length; j++) {
                if (j == selectedOptionTextValue) {
                    return elementValue[j];
                }
            }
        }
    }
    const getDate = (index: any) => {
        for (let i = 0; i < props.bodyTableValue.length; i++) {
            const elementValue: any = props.bodyTableValue[i];
            for (let j = 0; j < elementValue.length; j++) {
                if (j == selectedOptionDateValue) {
                    return elementValue[j];
                }
            }
        }
    }
    const getUsers = (index: any) => {
        for (let i = 0; i < props.bodyTableValue.length; i++) {
            const elementValue: any = props.bodyTableValue[i];
            for (let j = 0; j < elementValue.length; j++) {
                if (j == selectedOptionUsersValue) {
                    return elementValue[j];
                }
            }
        }
    }

    const save = (e: any) => {
        let dataObjValue = new Data();
        let textValue: any;
        let linesValue: any[] = [];

        for (let i = 0; i < props.bodyTableValue.length; i++) {
            const elementValue: any = props.bodyTableValue[i];
            for (let j = 0; j < elementValue.length; j++) {
                textValue = {
                    "text": elementValue[selectedOptionTextValue],
                    "user": elementValue[selectedOptionUsersValue],
                    "date": elementValue[selectedOptionDateValue]
                }
            }
            linesValue.push(textValue);
        }
        dataObjValue.workflow_id = props.workflow_id as any;
        dataObjValue.data = linesValue;
        dataObjValue.category = "text";
        dataObjValue.user_id = "";
        dataObjValue.sub_category = "csv_file";
        dataObjValue.keywords=props.keywordsValue.split(',');
        props.onImportDataText(dataObjValue);
    }
    return (
        <div>
            <div className="">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-table">
                                <div className="card-table-body">
                                    <h1 className="mt-0 header-title">Select Data Fields</h1>
                                    <h6 style={{ color: "#707070" }}>Map the CSV columns with the following fields</h6>
                                    <div id="collapse1">
                                        <div className="table-responsive upload">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <select className="form-control" id="searchType" onChange={e => handleChangeText(e)} value={selectedOptionTextValue}>
                                                                <option value="">Select Text</option>
                                                                {props.headerTableValue.map((text, index) => <option value={index} key={"headerText_" + index} >{text}</option>)}
                                                            </select>
                                                        </th>
                                                        <th style={{width: '150px'}}><select className="form-control" id="searchType" onChange={e => handleChangeDate(e)} value={selectedOptionDateValue}>
                                                            <option value="">Select Date</option>
                                                            {props.headerTableValue.map((date, index) => <option value={index} key={"headerDate_" + index} >{date}</option>)}
                                                        </select></th>
                                                        <th style={{width: '150px'}}> <select className="form-control" id="searchType" onChange={e => handleChangeUsers(e)} value={selectedOptionUsersValue}>
                                                            <option value="">Select Users</option>
                                                            {props.headerTableValue.map((user, index) => <option value={index} key={"headerUsers_" + index} >{user}</option>)}
                                                        </select></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { props.bodyTableValue.map((lines, index) => {
                                                        return <tr key={"line_" + index}>
                                                            <td key={"columnText_" + index}>{getText(index)}</td>
                                                            <td key={"columnDate_" + index}>{getDate(index)}</td>
                                                            <td key={"columnUsers_" + index}>{getUsers(index)}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-md-end  mt-4">
                                        <Button color="primary" disabled={isUploadData==false} onClick={(e) => { save(e) }} >Import</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ImportDataText