import React, { useEffect, useState } from 'react';
import { Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getSentimentByTopic } from '../../Services/ChartServices';
import Spinner from '../Spinner';
import { BarChart, BarData } from '../../Models/BarChart';

ChartJS.register(CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend);

export const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
          display: false
      }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
}; 
 
const HorizontalBarChart: React.FunctionComponent<{type :string,workflow_id:string}> = (props) => {

   
  const [isDataSet, setIsDataSet]=useState<boolean>(false);
  const [data, setData]=useState<any>(null);
  const [isSpinner, setIsSpinner] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(()=>{
    getSentimentByTopic(props.type,props.workflow_id).then((response)=>{
      if (response.data.msg = "success") {
       let chartObj = new BarChart(response.data.data.labels)
       response.data.data.datasets.map((data) => {
         let dataObj = new BarData(data.label, data.data, data.backgroundColor, data.borderColor);
         chartObj.pushDataSet(dataObj);
       })
          setIsDataSet(true)
          setIsSpinner(false);
          setIsError(false);
          setData(chartObj);
      }
      else if (response.data.msg = "error") {
          setErrorMessage("error");
      }
     })
     .catch(err => {
       setIsError(true);
       setErrorMessage("Error");

   })

  },[])
  
     return <div>
        {isSpinner == true && isError==false && <div style={{marginTop: "50px", textAlign:"center"}}><Spinner/> </div> }
   {isError==true && <h3 style={{color:'red',marginTop: "50px", textAlign:"center"}}> {errorMessage}</h3>}
      
      {isSpinner == false && isDataSet==true && <Bar options={options} data={data as any} />}</div>
}
export default HorizontalBarChart 