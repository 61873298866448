import axios from "axios";
import { getUserId } from "./Authentication.service";

const url = "https://api.coralytics.com";
// Update Profile Image
export const updateProfileImage = (image) => {
    const apiUrl=url+"/update_user_profile_image";
    const request={"image":image}
    return axios.post(apiUrl,request,{
      headers: {
        "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "")
      }
    }).then((response) => {
      const dataobj =response.data
         return dataobj
    }); 
  }

// Update Profile
export const updateUserDetails = (userValue) => {
    const apiUrl=url+"/update_user_details";
    return axios.post(apiUrl,userValue,{
      headers: {
        "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "")
      }
    }).then((response) => {
    return response.data;
    })
  }
  
  // update Password
  export const updateUserPassword = (userValue) => {
    const apiUrl=url+"/update_user_password";
    return axios.post(apiUrl,userValue,{
      headers: {
        "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "")
      }
    }).then((response) => {
    return response.data;
    })
  }

  export const getUserProfileImage = (userValue) => {
    const apiUrl=url+"/get_user_profile_image";
    const request={}
    return axios.post(apiUrl,request,{
      headers: {
        "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "")
      }
    }).then((response) => {
    return response.data;
    })
  }