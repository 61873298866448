import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { logout } from '../../Services/Authentication.service';
import $ from 'jquery';

const HeaderDash: React.FunctionComponent<{profileImage :any}> = (props) => {

    const navigate = useNavigate();

    const getSidebar=(event)=>{
        event.preventDefault();
        $("body").toggleClass("enlarged");
    }
    
    const logoutUser = () => {
        logout();
        navigate("/login");
    }

    const profile = () => {
        navigate("/profile");
    }

    return (
        <div className="topbar">
            <nav className="navbar-custom">
                <ul className="list-inline">
                    <li className="hide-phone list-inline-item ">
                        {/* <a className="title_dash" href='/projects' id="now_routing"> BrandForce</a> */}
                       <a href='/projects' id="now_routing"><img src="/assets/images/Brandforcelogo.png" alt="" width={'180px'} height={'70px'} style={{marginLeft:'10px'}}/></a> 
                    </li>
                    <li className="list-inline-item menu-left mb-0">
                        <button type="button"  onClick={(e) => {getSidebar(e)}} className="button-menu-mobile open-left waves-effect">
                            <i className="fas fa-bars"></i>
                        </button>
                    </li>
                    <li className="list-inline-item dropdown notification-list " style={{ float: 'right', marginTop: '20px', marginRight: "20px" }}>

                        <div className=" dropdown">
                            <a className="dropdown-toggle" id="messages" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#">
                                <img src={props.profileImage} />
                            </a>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <button className="dropdown-item" onClick={profile}><i className="fas fa-user" style={{ marginRight: '5px', color: '#0014FF' }}></i>Profile</button>
                                <button className="dropdown-item" onClick={logoutUser}><i className="fas fa-power-off" style={{ marginRight: '5px', color: 'red' }}></i>Logout</button>
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default HeaderDash