import React from 'react'
import AudioTable from './Audio/AudioTable'
import TextCsv from './Text/TextCsv'

const DataWrapper:React.FunctionComponent<{type :string , workflow_id:string}> = (props) =>{

    return (
        <div className="card m-b-20 height_table ">
            <div className="card-body">
            {props.type=="audio" &&  <AudioTable workflow_id={props.workflow_id}/>}
            {props.type=="text" &&  <TextCsv workflow_id={props.workflow_id}/>}
            </div>
        </div>
    )
}
export default DataWrapper