export class Data{
    id :string;
    user_id: string;
    workflow_id : string;
    category :string;
    sub_category: string;
    data : any;
    keywords:any;

    constructor(){
        this.id = "";
        this.user_id = "";
        this.category="";
        this.sub_category="";
        this.workflow_id = "";
        this.data ={};
        this.keywords={};
    }
}