import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';

const Sidebar: React.FunctionComponent<{statusProject:any ,workflow_id:any, profileImage:any}> = (props) => {

    let location = useLocation();
    const [user,setUser]=useState<any>("admin");
    

    return  (

        <div className="left sidebar">
            <div className="profile">
                <img src={props.profileImage} alt="profile_picture" />
                <h3>Admin</h3>
            </div>
            <ul>
               { user=="admin" && <li>
               <Link to={`/analytics/${props.workflow_id}`} className={[location.pathname == `/analytics/${props.workflow_id}` ? "active" : "", props.statusProject == "processing" ? 'disabled-link' : ""].join(" ")}>
                        <span className="icon"><i className="fas fa-chart-line"></i></span>
                        <span className="item">Analytics</span>
                    </Link>
                </li>}
                <li>
                    <Link to={`/addData/${props.workflow_id}`} className={location.pathname == `/addData/${props.workflow_id}` ? "active" : ""}>
                        <span className="icon"><i className="fas fa-upload"></i></span>
                        <span className="item">Upload</span>
                    </Link>
                </li>
                <li>
                    <Link to={`/projectDetails/${props.workflow_id}`}  className={location.pathname == `/projectDetails/${props.workflow_id}` ? "active" : ""}>
                        <span className="icon"><i className="fas fa-desktop"></i></span>
                        <span className="item">Project Details</span>
                    </Link>
                </li>
                { user=="admin" && <li>
                    <Link to="/projects" className={location.pathname == "/projects" ? "active" : ""}>
                        <span className="icon"><i className="fas fa-list"></i></span>
                        <span className="item">Projects</span>
                    </Link>
                </li>}
            </ul>
        </div>
    ) 
}

export default Sidebar;