import React, { useEffect, useState } from 'react'
import { getUserDetails, getUserId} from '../../Services/Authentication.service';
import { getUserProfileImage, updateUserDetails, updateUserPassword } from '../../Services/Profile';
import HeaderDash from '../Header/HeaderDash';
import ProfileImage from './ProfileImage';

const Profile: React.FunctionComponent<{}> = (props) => {

    const [isUser, setIsUser] = useState<boolean>(false)
    const [lastName, setLastName] = useState<string>()
    const [errorLastName, setErrorLasttName] = useState<string>("");
    const [errorFirstName, setErrorFirstName] = useState<string>("");
    const [errorNewPassword, setErrorNewPassword] = useState<string>("");
    const [errorConfirmPassword, setErrorConfirmPassword] = useState<string>("");
    const [firstName, setFirstName] = useState<string>()
    const [newPassword, setNewPassword] = useState<string>()
    const [confirmPassword, setConfirmPassword] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [role, setRole] = useState<string>()
    const [isError, setIsError] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isChange, setIsChange] = useState<boolean>(false);
    const [userId, setUserId] = useState<any>(getUserId())
    const [messageError, setMessageError] = useState<any>();
    const [messageSuccess, setMessageSuccess] = useState<any>();
    const [errorOldPassword, setErrorOldPassword] = useState<any>();
    const [oldPassword, setOldPassword] = useState<any>();
    const [profileImage, setProfileImage] = useState<any>("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");
    const [isProfileImage , setIsProfileImage] = useState<boolean>(false);

    useEffect(() => {
        getUserDetails(userId).then((response) => {
            setIsUser(true);
            setLastName(response.user_object.lastname)
            setFirstName(response.user_object.firstname)
            setEmail(response.user_object.email)
            setRole(response.user_object.role)
        }).catch(err => {
           console.log("error")
        })
        getUserProfileImage(getUserId()).then((response) => {
            setProfileImage(response.profile_image)
            setIsProfileImage(true);
        }).catch(err => {
           console.log("error")
        })
    }, [])

    const changeLastName = (e) => {
        setLastName(e.target.value);
        setErrorLasttName("")
    }
    const changeFirstName = (e) => {
        setFirstName(e.target.value);
        setErrorFirstName("")
    }
    const changeNewPassword = (e) => {
        setNewPassword(e.target.value)
    }
    const confirmNewPassword = (e) => {
        setConfirmPassword(e.target.value)
    }
    const changeOldPassword = (e) => {
        setOldPassword(e.target.value)
    }
 
    // Update Password
    const updatePassword = () => {
        setErrorConfirmPassword("");
        setErrorNewPassword("");
        setErrorOldPassword("")
        if (!newPassword) {
            setErrorNewPassword("This field cannot be empty");
        }
        if (!confirmPassword) {
            setErrorConfirmPassword("This field cannot be empty");
        }
        if (!oldPassword) {
            setErrorOldPassword("This field cannot be empty")
        }
        if (newPassword == confirmPassword) {
            const userValue = {
                "user_id": userId,
                "password": newPassword,
                "old_password": oldPassword,
                "repassword": confirmPassword
            }
            setIsError(false)
            setIsSuccess(false)
            updateUserPassword(userValue).then((response) => {
                if (response.msg == "success") {
                    setMessageSuccess("Password Updated Successfully ");
                    setIsSuccess(true)
                    setIsChange(false)
                    setConfirmPassword("");
                    setOldPassword("");
                    setNewPassword("");
                } else if (response.msg == "wrong_old_password") {
                    setErrorOldPassword("Wrong Old Password")
                }
                else {
                    setMessageError(response.msg);
                    setIsError(true)
                }
            }).catch(err => {
                setIsError(true);
                setMessageError("Error");
            })
            setTimeout(() => {   
                setIsError(false);
                setIsSuccess(false)
                }, 3000);
        }
        else {
            setErrorConfirmPassword("Passwords must match");
            setErrorNewPassword("Passwords must match");
        }
        
    }

//  Update Profile
    const updateProfile = () => {

        if (!firstName) {
            setErrorFirstName("This field cannot be empty");
        }
        if (!lastName) {
            setErrorLasttName("This field cannot be empty");
        }
        else {
            const userValue = {
                "user_id": userId,
                "firstname": firstName,
                "lastname": lastName
            }
            updateUserDetails(userValue).then((response) => {
                if (response.msg = "success") {
                    setMessageSuccess("Profile Updated Successfully ");
                    setIsSuccess(true)
                } else {
                    setMessageError(response.msg);
                    setIsError(true)
                }
            }).catch(err => {
                setIsError(true);
                setMessageError("Error");
            })
            setTimeout(() => {   
                setIsError(false);
                setIsSuccess(false)
                }, 3000);
        }
    }

    const changePassword = () => {
        setIsChange(!isChange)
    }

    return isUser ? (
        <div>
            <HeaderDash profileImage={profileImage} />
            <div className='container'>
                <div className="row"> 
                    <div className="col-12">
                    <div className="card-workflow  margin-profile">
                        <div className="card-workflow-body">
                                <h1 className="mt-0 header-title" style={{ textAlign: 'center' }}>Profile</h1>
                                {isSuccess == true && <div className="alert alert-success" role="alert">
                                    {messageSuccess}
                                </div>}
                                {isError == true && <div className="alert alert-danger" role="alert">
                                    {messageError}
                                </div>}
                             { isProfileImage==true &&  <ProfileImage image={profileImage}/>}
                                <div>
                                <div className="mb-3">
                                    <label htmlFor="first_name" className="form-label"> First Name</label>
                                    <input type="text" className="form-control" id="first_name" value={firstName} onChange={(e) => { changeFirstName(e) }} />
                                  { errorFirstName!="" && <span className="text-danger small">{errorFirstName}</span>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="last_name" className="form-label"> Last Name</label>
                                    <input type="text" className="form-control" id="last_name" value={lastName} onChange={(e) => { changeLastName(e) }} />
                                    {errorLastName!="" && <span className="text-danger small">{errorLastName}</span>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label"> Email</label>
                                    <input type="email" className="form-control" id="email" value={email} disabled />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="role" className="form-label"> Role</label>
                                    <input type="text" className="form-control" id="role" value={role} disabled />
                                </div>
                                <div className="mb-3 d-flex justify-content-end">
                                    <button className='btn btn-primary' onClick={updateProfile} >Update Profile</button>
                                </div>
                                </div>
                                <div className="mb-3 d-flex justify-content-end">
                                    <a className="change-password" style={{textDecoration: 'underline' , color : 'grey'}} onClick={changePassword}>Change Password</a>
                                </div>
                                {isChange == true &&  <div >
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Old Password</label>
                                        <input type="password" className="form-control" id="password1" value={oldPassword} onChange={(e) => { changeOldPassword(e) }} />
                                       {errorOldPassword!="" && <span className="text-danger small">{errorOldPassword}</span>}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">New Password</label>
                                        <input type="password" className="form-control" id="password" value={newPassword} onChange={(e) => { changeNewPassword(e) }} />
                                        {errorNewPassword!="" && <span className="text-danger small">{errorNewPassword}</span>}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="confirm_password" className="form-label">Confirm Password</label>
                                        <input type="password" className="form-control" id="confirm_password" value={confirmPassword} onChange={(e) => { confirmNewPassword(e) }} />
                                       {errorConfirmPassword!="" && <span className="text-danger small">{errorConfirmPassword}</span>}
                                    </div>
                                    <div className="mb-3 d-flex justify-content-end">
                                        <button className='btn btn-primary' onClick={updatePassword}>Update Password</button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>             
                </div>  
            </div>
        </div>
    ) :<></>
}

export default Profile
