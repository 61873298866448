import React from 'react'
import axios from "axios";

const apiUrl = "https://api.coralytics.com/admin/login";
const url = "https://api.coralytics.com";
export interface TokenPayload {
  password: string
  email: string 
}
export const login = (user :TokenPayload) => {
  return axios.post(apiUrl,user).then((response) => {
      if (response.data.msg) {
        saveToken(JSON.stringify(response.data.token));
      }
      return response.data;
    });
};

export const saveToken = (token :any) => {
  localStorage.setItem('usertoken',token)
}

export const logout = () =>{
  localStorage.removeItem('usertoken');
 
}

export const isLogged=()=>{
let token = localStorage.getItem('usertoken')
return !!token
}

export const getUserId=()=> {
  const user :any = getCurrentUser(); 
  return user.user_id;
}

export const getCurrentUser = () => {
  const token = localStorage.getItem("usertoken");
  let payload
  if (token) {
    payload = token.split('.')[1]

    try{
      payload = window.atob(payload)
    }catch(err){
      return null
    }
    return JSON.parse(payload).sub
  } else {
    return null
  }
};


export const getUserDetails= (user_id)=> {
  const apiUrl=url+"/get_user_details";
  const user={}
  return axios.post(apiUrl,user,{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("usertoken")|| "")
    }
  }).then((response) => {
    return response.data;
    })
}


