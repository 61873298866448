import { Navigate, useParams } from "react-router-dom";
import { isLogged, saveToken } from "../Services/Authentication.service";

const AuthRedirectGuard:React.FunctionComponent<{children}> = (props) =>{

  let {usertoken}  = useParams();
  if(!isLogged()){
      saveToken(JSON.stringify(usertoken));
      return <Navigate to="/projects"/>
    }  
    else{
      return <Navigate to="/projects"/>
    }
}
 
export default AuthRedirectGuard;