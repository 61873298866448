import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../components/Login/Login';
import Workflow from '../components/Workflow/Workflow';
import AuthGuard from '../Authentication/AuthGuard';
import PublicPagesGuard from '../Authentication/PublicPagesGuard';
import AuthRedirectGuard from '../Authentication/AuthRedirectGuard';
import AuthRedirect from '../components/Login/AuthRedirect';
import CreateWorkflow from '../components/Workflow/CreateWorkflow';
import Analytics from '../components/Analytics/Analytics';
import ListProject from '../components/Workflow/ListProjects';
import ProjectDetails from '../components/Workflow/ProjectDetails';
import AddData from '../components/Workflow/AddData';
import Profile from '../components/Profile/Profile';
import Layout from '../components/Layout/Layout';

const PublicRouter: React.FunctionComponent<{}> = (props) => {

    return (
        <div>
            <Routes>
            <Route path="" element={<PublicPagesGuard><Login /></PublicPagesGuard>} />
            <Route path="/" element={<Layout />} >
            <Route path="addData/:workflow_id" element={<AuthGuard><AddData/></AuthGuard>} />
            <Route path="projectDetails/:workflow_id" element={<AuthGuard>< ProjectDetails /></AuthGuard>} />
            <Route path="analytics/:workflow_id" element={<AuthGuard><Analytics /></AuthGuard>} />
            </Route>
                <Route path="/projects" element={<AuthGuard><ListProject /></AuthGuard>} />
                <Route path="/workflow" element={<AuthGuard><CreateWorkflow /></AuthGuard>} />
                <Route path="/upload/:workflow_id" element={<AuthGuard><Workflow /></AuthGuard>} />
                <Route path="/profile" element={<AuthGuard><Profile /></AuthGuard>} />
                <Route path="/login" element={<PublicPagesGuard><Login /></PublicPagesGuard>} />
                <Route path="authRedirect/:usertoken" element={<AuthRedirectGuard><AuthRedirect /></AuthRedirectGuard>} />
            </Routes>
        </div>
    );
};

export default PublicRouter;