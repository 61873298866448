import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { delete_project, getProjects } from '../../Services/WorkflowServices';
import Pagination from '../Pagination';
import HeaderDash from '../Header/HeaderDash';
import Spinner from '../Spinner';
import { getUserProfileImage } from '../../Services/Profile';

const ListProject: React.FunctionComponent<{}> = (props) => {

    const [isDataSet, setIsDataSet] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const [numberPage, setNumberPage] = useState<number>(1);
    const [isSpinner, setIsSpinner] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<any>();
    const [messageDelete,setMessageDelete] = useState<any>();
    const [dataLenght, setDataLenght] = useState<any>();
    const [isError, setIsError] = useState<boolean>(false);
    const [nameProjectValue, setNameProjectValue] = useState<string>("");
    const navigate = useNavigate();
    const [idProject,setIdProject]=useState<string>("");
    const [nameProject,setNameProject]=useState<string>("");
    const [profileImage, setProfileImage] = useState<any>("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");
    const [isSuccessDelete, setIsSuccessDelete] = useState<boolean>(false);
    const [isErrorDelete, setIsErrorDelete] = useState<boolean>(false);
 
    useEffect(() => {
     const listProject= getProjects(numberPage).then((data) => {
            if (data.data.msg = "success") {
                setDataLenght(data.data.data.length)
                setIsDataSet(true)
                setIsSpinner(false);
                setIsError(false);
                setNumberPage(data.data.page_count)
                setData(data.data.data);
            }
            else if (data.data.msg = "error") {
                setErrorMessage("Error");
            }
        }
        ).catch(err => {
            setIsError(true);
            setErrorMessage("Error");
        })
        getUserProfileImage("").then((response) => {
            setProfileImage(response.profile_image)
          }).catch(err => {
             console.log("error")
          })
    }, [])

    const createProject = () => {
        navigate('/workflow');
    }

    const view = (workflow_id) => {
        navigate(`/projectDetails/${workflow_id}`);
    }

    const visit = (workflow_id) => {
        navigate(`/analytics/${workflow_id}`);
    }

    const paginateHandler = (pageNumber) => {
        getProjects(pageNumber).then((data) => {
            setNumberPage(data.data.page_count)
            setData(data.data.data)
        }).catch(err => {
            setIsError(true);
            setErrorMessage("Error");
        })
    }
    const Cancel =()=>{
        document.getElementById("closeRemoveModal")?.click()  ;
        setNameProjectValue("")
    }
    
    const deleteProject=()=>{
        document.getElementById("closeRemoveModal")?.click();
        setNameProjectValue("")
            delete_project(idProject,nameProject).then((data)=>{
                if (data.msg = "success") {
                setIsSuccessDelete(true);
                setMessageDelete("Project Deleted Successfully");
                getProjects(numberPage).then((data) => {
                    if (data.data.msg = "success") {
                    setDataLenght(data.data.data.length)
                    setIsDataSet(true)
                    setIsSpinner(false);
                    setIsError(false);
                    setNumberPage(data.data.page_count)
                    setData(data.data.data);
                }
                else if (data.data.msg = "error") {
                    setErrorMessage("Error");
                }
                }).catch(err => {
                    setIsError(true);
                    setErrorMessage("Error");
                });}
                else if (data.msg = "error") {
                    setIsErrorDelete(true);
                     setMessageDelete("Error");
                }
            }
            ).catch(err => {
                setIsErrorDelete(true);
                setMessageDelete("Error");
            });
            setTimeout(() => {   
                setIsErrorDelete(false);
                setIsSuccessDelete(false)
                }, 3000);
    }

    return (
        <>
            <HeaderDash profileImage={profileImage} />
            <div className='page_project'>
                <div className="row">
                    <div className="col-12">
                        <div className="card-table">
                            <div className="card-table-body">
                                <h1 className="mt-0 header-title d-flex justify-content-start">Projects List</h1>
                                <div id="collapse1">
                                    <div className='d-flex justify-content-end' style={{ marginBottom: "5px" }}><button className="btn btn-primary" onClick={(e) => { createProject() }}><i className="fas fa-plus"></i></button></div>
                                    {isSuccessDelete == true && <div className="alert alert-success" role="alert">
                                    {messageDelete}
                                </div>}
                                   {isErrorDelete == true && <div className="alert alert-danger" role="alert">
                                    {messageDelete}
                                </div>}
                                    <div className="table-responsive">
                                   
                                        <table className="table mb-0">
                                            <thead className="theadColor">
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Category</th>
                                                    <th>Data Count</th>
                                                    <th>Status</th>
                                                    <th>Details</th>
                                                    <th>Analytics</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isSpinner == false && isDataSet == true && data.map((lines, index) => {
                                                    return <tr key={"line_" + index}>
                                                        <td>{lines.workflow_name}</td>
                                                        <td>{lines.category}</td>
                                                        <td>{lines.data_count}</td>
                                                        <td><span className={[lines.status == "processing" ? "badge badge-pill badge-orange" : " ", lines.status == "ready" ? "badge badge-pill badge-primary" : " "].join(" ")}>{lines.status}</span></td>
                                                        <td><button className="btn btn-light btn-sm" onClick={(e) => { view(lines._id) }}><i className="fas fa-eye"></i></button></td>
                                                        <td><button className="btn btn-teal btn-sm" disabled={lines.status == "processing"} onClick={() => { visit(lines._id) }}>Visit</button></td>
                                                        <td><button className="btn btn-danger btn-sm" data-toggle="modal" data-target="#delete" disabled={lines.status == "processing"}  onClick={()=>{setIdProject(lines._id); setNameProject(lines.workflow_name)}}><i className="fas fa-trash"></i></button></td>

                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    {isSpinner == false && dataLenght != 0 && <Pagination pageCount={numberPage} onPaginate={paginateHandler} />}
                                    {dataLenght == 0 && isSpinner == false && isError == false && <h3 style={{ fontSize: "16px" }}> Is List Empty</h3>}
                                    {isError == true && <h3 style={{ color: 'red' }}> {errorMessage}</h3>}
                                </div>
                                {isSpinner == true && isError == false && <Spinner />}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="delete" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title" id="exampleModalLabel">Drop Project</h6>
                                <button type="button" className="close" id="closeRemoveModal" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <label htmlFor="nameProject" className="form-label">Name Project</label>
                                <input type="text" value={nameProjectValue} onChange={(e) => {
                                    setNameProjectValue(e.target.value);
                                }} className="form-control" />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={()=>{Cancel()}}>Cancel</button>
                                <button type="button" className="btn btn-danger" onClick={()=>{deleteProject()}} disabled={nameProjectValue!==nameProject}>Drop</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default ListProject