import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ImportDataText from './ImportDataText';
import { get_project_details, UploadDataService } from '../../Services/WorkflowServices';
import AddUploadData from './AddUploadData';

const AddData: React.FunctionComponent<{}> = (props) => {


  const [isShowUploadCsv, setShowUploadCsv] = useState<boolean>(true);
  const [isShowTable, setShowTable] = useState<boolean>(false);
  // const [isShowAudio, setShowAudio] = useState<boolean>(false);
  const [bodyTableValue, setBodyTable] = useState<Array<any>>([]);
  const [headerTableValue, setHeaderTable] = useState<Array<any>>([]);
  const [keywordsValue, setKeywords] = useState<any>();

  let navigate = useNavigate();
  let { workflow_id } = useParams();
  const [dataDetails, setDataDetails] = useState<any>(null);
  const [isdataDetails, setIsDataDetails] = useState<any>(null);

  useEffect(() => {
    get_project_details(workflow_id).then((data) => {
      setDataDetails(data);
      setIsDataDetails(true)
    });

  },[])
    
  const showUploadCsvHandler = (headerValue: any, bodyValue: any, keywordsValue: any, isShowUpload: boolean, isShowtable: boolean) => {
    setHeaderTable(headerValue);
    setBodyTable(bodyValue);
    setKeywords(keywordsValue);
    setShowUploadCsv(isShowUpload);
    setShowTable(isShowtable);
  };

  const importDataTextHandler = (dataObjValue: any) => {
    UploadDataService(dataObjValue).then((response) => {
      navigate(`/projectDetails/${workflow_id}`);
    }).catch(err => {
      console.log("Error");
  })
  }

  // const importDataAudioCsvHandler = (dataObjValue: any) => {
  //   UploadDataService(dataObjValue).then((response) => {
  //     navigate(`/projectDetails/${workflow_id}`);
  //   }).catch(err => {
  //     console.log("Error");
  // })
  // }

  const importDataAudioFilesHandler = () => {
      navigate(`/projectDetails/${workflow_id}`);
  }

  return isdataDetails ? (
    <div>
    <div className="page-content-wrapper">
        <div className="container-fluid">
          {isShowUploadCsv == true && <AddUploadData dataDetails={dataDetails} workflow_id={workflow_id} onShowUploadData={showUploadCsvHandler} onImportDataAudioFiles={importDataAudioFilesHandler} />}
          {(isShowTable == true ) && <ImportDataText headerTableValue={headerTableValue} onImportDataText={importDataTextHandler} bodyTableValue={bodyTableValue} keywordsValue={keywordsValue} workflow_id={workflow_id} />}
          {/* {(isShowTable == true && isShowAudio == true) && <ImportDataAudio headerTableValue={headerTableValue} bodyTableValue={bodyTableValue} workflow_id={workflow_id} keywordsValue={keywordsValue} onImportDataAudioCsv={importDataAudioCsvHandler} />} */}
        </div>
      </div>
    </div>
  ):<></>
}

export default AddData