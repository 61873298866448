export class Workflow{
    id :string;
    user_id: string;
    workflow_name: string;
    
    constructor(){
        this.id = "";
        this.user_id = "";
        this.workflow_name = "";
    }
}