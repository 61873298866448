import Barchart from "../Charts/Barchart"
import DonutBrand from "../Charts/DonutBrand"
import DonutEmotion from "../Charts/DonutEmotion"
import DonutPersonality from "../Charts/DonutPersonality "
import DonutSentiment from "../Charts/DonutSentiment"
import DonutTonality from "../Charts/DonutTonality"
import HorizontalBarChart from "../Charts/HorizontalBarChart"
import LineBrand from "../Charts/LineBrand"
import Linechart from "../Charts/Linechart"
import LineEmotion from "../Charts/LineEmotion"
import LinePersonality from "../Charts/LinePersonality"
import LineTonality from "../Charts/LineTonality"
import WordCloud from "../Charts/WordCloud"

const ChartsWrapper: React.FunctionComponent<{ type: string, workflow_id: string }> = (props) => {

    return (
        <div className="card m-b-20 height_table ">
            <div className="card-body">
                <div className="row">
                    <div className="col-xl-6 ">
                        <div className="card m-b-20 card-height card-analytics">
                            <div className="card-body ">
                                <h4 className="mt-0 header-chart ">Topic</h4>
                                <div style={{ height: '13vw' }} > <Barchart type={props.type} workflow_id={props.workflow_id} /></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 ">
                        <div className="card m-b-20 card-height card-analytics">
                            <div className="card-body ">
                                <h4 className="mt-0 header-chart">Sentiment By Topic</h4>
                                <div className='horizontalBar' style={{ height: "13vw" }}>  <HorizontalBarChart type={props.type} workflow_id={props.workflow_id} /></div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-xl-6 ">
                        <div className="card m-b-20 card-height card-analytics">
                            <div className="card-body  ">
                                <h4 className="mt-0 header-chart">Overall Sentiment</h4>
                                <div className="donut-sentiment">
                                    <DonutSentiment type={props.type} workflow_id={props.workflow_id} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 ">
                        <div className="card m-b-20 card-height card-analytics">
                            <div className="card-body ">
                                <h4 className="mt-0 header-chart">Sentiment Over Time</h4>
                                <div style={{ height: "13vw" }}>   <Linechart type={props.type} workflow_id={props.workflow_id} /></div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">

                    <div className="col-xl-6 ">
                        <div className="card m-b-20 card-height card-analytics">
                            <div className="card-body ">
                                <h4 className="mt-0 header-chart">Emotion Analysis</h4>
                                <div className="donut-sentiment">
                                    <DonutEmotion type={props.type} workflow_id={props.workflow_id} /></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 ">
                        <div className="card m-b-20 card-height card-analytics">
                            <div className="card-body ">
                                <h4 className="mt-0 header-chart">Emotion Over Time</h4>
                                <div style={{ height: "13vw" }}>   <LineEmotion type={props.type} workflow_id={props.workflow_id} /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 ">
                        <div className="card m-b-20 card-height card-analytics">
                            <div className="card-body ">
                                <h4 className="mt-0 header-chart">Communication And Tonality</h4>
                                <div className="donut-sentiment">
                                    <DonutTonality type={props.type} workflow_id={props.workflow_id} /></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 ">
                        <div className="card m-b-20 card-height card-analytics">
                            <div className="card-body ">
                                <h4 className="mt-0 header-chart">Communication Over Time</h4>
                                <div style={{ height: "13vw" }}>   <LineTonality type={props.type} workflow_id={props.workflow_id} /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-xl-6 ">
                        <div className="card m-b-20 card-height card-analytics">
                            <div className="card-body ">
                                <h4 className="mt-0 header-chart">Personality Traits</h4>
                                <div className="donut-sentiment">
                                    <DonutPersonality type={props.type} workflow_id={props.workflow_id} /></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 ">
                        <div className="card m-b-20 card-height card-analytics">
                            <div className="card-body ">
                                <h4 className="mt-0 header-chart">Personality Over Time</h4>
                                <div style={{ height: "13vw" }}>   <LinePersonality type={props.type} workflow_id={props.workflow_id} /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 ">
                        <div className="card m-b-20 card-height card-analytics">
                            <div className="card-body ">
                                <h4 className="mt-0 header-chart">Brand Recommendation</h4>
                                <div className="donut-sentiment">
                                    <DonutBrand type={props.type} workflow_id={props.workflow_id} /></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 ">
                        <div className="card m-b-20 card-height card-analytics">
                            <div className="card-body ">
                                <h4 className="mt-0 header-chart">Brand Over Time</h4>
                                <div style={{ height: "13vw" }}>   <LineBrand type={props.type} workflow_id={props.workflow_id} /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 ">
                    <div className="card m-b-20 card-height card-analytics">
                    <div className="card-body App ">
                                <WordCloud type={props.type} workflow_id={props.workflow_id} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChartsWrapper
