import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap';
import { Workflow } from '../../Models/Workflow';
import { CreateWorkflowService } from '../../Services/WorkflowServices';
import { useNavigate } from 'react-router-dom';
import HeaderDash from '../Header/HeaderDash';
import { getUserProfileImage } from '../../Services/Profile';

const CreateWorkflow: React.FunctionComponent<{}> = (props) => {

    const [nameValue, setName] = useState<string>("");
    const [errorName, setErrorName] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<any>();
    const [isError, setIsError] = useState<boolean>(false);
    const [profileImage, setProfileImage] = useState<any>("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

    let navigate = useNavigate();

    useEffect(() => {
        getUserProfileImage("").then((response) => {
          setProfileImage(response.profile_image)
        }).catch(err => {
           console.log("error")
        })
    }, [])
    const handleSubmit = (e) => {
        let workflowObject = new Workflow();
        e.preventDefault();
        if (!nameValue) {
            setErrorName("This field cannot be empty");
        }
        else {
            workflowObject.workflow_name = nameValue;
            workflowObject.user_id = "";
            CreateWorkflowService(workflowObject).then((response) => {
                if(response.msg="success"){
                    console.log(response)
                    setIsError(false);
                    navigate(`/upload/${response.workflow_id}`);
                }else{
                    setIsError(true);
                    setErrorMessage("Error");
                }
            }).catch(err => {
            setIsError(true);
            setErrorMessage("Error");
            })
            setTimeout(() => {   
                setIsError(false);
                }, 3000);
        }
    };

    return (
        <div>
            <HeaderDash profileImage={profileImage}/>
            <div className='container'>
                <div className="row ">
                    <div className="col-12 ">
                        <div className="card-create-workflow">
                            <div className="card-workflow-body">
                            <h1 className="mt-0 header-title">Name your project</h1>
                                <h6 className='upload-data'>Set a name to your project</h6>
                                <div className="text-center"></div>
                                {isError==true && <div className="alert alert-danger m-1" role="alert">
                                   {errorMessage}
                                </div>}
                                <form onSubmit={handleSubmit} className=" m-1 p-2">
                                    <div className="form-group mt-2">
                                        <input
                                            type="text"
                                            value={nameValue}
                                            onChange={(e) => {
                                                setName(e.target.value);
                                                setErrorName("");
                                            }}
                                            className="form-control" placeholder="Sentiment + Keyword Analysis"
                                        />
                                       {errorName!="" && <span className="text-danger small">{errorName}</span>}
                                    </div>
                                    <div className="d-flex justify-content-end m-t-20" role="group" aria-label="Basic example">
                                        <Button color="primary">Create</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CreateWorkflow
